import React from 'react'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import tw from 'twin.macro'

import { MainLayout } from '@common/layouts'
import { PaginationLayout } from '@common/layouts'

export const query = graphql`
  query Strains($skip: Int!, $limit: Int!) {
    allStrain(
      sort: { fields: [image___root, publishedAt], order: [ASC, DESC] }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          name
          strapiId
          slug
          publishedAt(formatString: "MMMM D, YYYY")
          image {
            childImageSharp {
              fluid(quality: 50, maxWidth: 400) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          childMarkdownRemark {
            excerpt(pruneLength: 80)
          }
        }
      }
    }
  }
`

const ListWrapper = styled.div`
  ${tw`flex-1 grid grid-cols-1 gap-16 my-8 mx-8`}
  ${tw`md:(grid-cols-2 my-12)`}
  ${tw`lg:(grid-cols-3)`}
`

const ImageWrapper = styled.div`
  ${tw`relative w-full mb-4`}
`

const StrainsListPage = ({
  data: { allStrain },
  pageContext,
  Layout = MainLayout,
}) => {
  const { currentPage, numPages } = pageContext

  return (
    <PaginationLayout
      title={`Strains`}
      path={'strains'}
      currentPage={currentPage}
      numPages={numPages}
      Layout={Layout}
    >
      <ListWrapper>
        {allStrain.edges.map(({ node: strain }) => (
          <Link
            to={`/strains/${strain.slug}`}
            title={`Strain: ${strain.name}`}
            tw="overflow-hidden cursor-pointer"
            key={strain.id}
          >
            <article>
              {strain.image && (
                <ImageWrapper>
                  <Img
                    tw="h-full w-full"
                    fluid={{
                      ...strain.image.childImageSharp.fluid,
                      aspectRatio: 4 / 3,
                    }}
                    alt={strain.name}
                  />
                </ImageWrapper>
              )}

              <h2 tw="text-xl text-gray-900 font-bold tracking-tight leading-6 mb-2">
                {strain.name}
              </h2>
              <p tw="text-sm text-gray-800 leading-6">
                {strain.childMarkdownRemark.excerpt}
              </p>
            </article>
          </Link>
        ))}
      </ListWrapper>
    </PaginationLayout>
  )
}

export default StrainsListPage
